import React from "react";
import { withRouter } from "react-router";
import ReactDOM from "react-dom";

//Ant Design Elements
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";

import api from "../../../helpers/api";

class ContactFormClass extends React.Component {
    state = {
        saving: false,
        submitButtonDisabled: true,
    };

    validateForm = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // No Errors : Disabled = False
                this.setState({
                    submitButtonDisabled: false,
                });
            } else {
                // Errors : Disabled = True
                this.setState({
                    submitButtonDisabled: true,
                });
            }
        });
    };

    goNextField = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            var inputs = document.querySelectorAll(
                "#contact_form input, #contact_form textarea"
            );

            var lastElement = true;
            for (var i = 0; i < inputs.length; i++) {
                if (inputs[i] === e.target && !!inputs[i + 1]) {
                    lastElement = false;
                    document.activeElement.blur();
                    document.activeElement.removeAttribute("autofocus");

                    inputs[i + 1].setAttribute("autofocus", "autofocus");
                    inputs[i + 1].focus();
                }
            }
            if (lastElement) {
                this.submitForm();
            }
        }
    };

    submitForm = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return false;
            }

            this.setState(
                {
                    saving: true,
                },
                () => {
                    if (!!this.props.user.id) {
                        values.student_id = this.props.user.id;
                    }
                    api.post("contact", {
                        body: JSON.stringify(values),
                    })
                        .then((data) => {
                            if (
                                typeof data.result === "object" &&
                                data.result.success
                            ) {
                                Modal.success({
                                    className: "unavailable-survey",
                                    title: "Thank you for your message",
                                    content: (
                                        <p>
                                            If required, we will get back to you
                                            as soon as we can.
                                        </p>
                                    ),
                                    icon: false,
                                    closable: false,
                                    keyboard: false,
                                });
                            } else {
                                Modal.error({
                                    className: "unavailable-survey",
                                    title: "Error!",
                                    content:
                                        "Sorry, an error occurred. Please try again!",
                                    icon: false,
                                    closable: false,
                                    keyboard: false,
                                });
                            }
                            this.setState({
                                saving: false,
                            });
                        })
                        .catch((error) => {
                            Modal.error({
                                className: "unavailable-survey",
                                title: "Error!",
                                content:
                                    "Sorry, an error occurred. Please try again!",
                                icon: false,
                                closable: false,
                                keyboard: false,
                            });
                            this.setState({
                                saving: false,
                            });
                        });
                }
            );
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        let ContactFormToRender = (
            <>
                <Form id="contact_form">
                    <Form.Item label="Your Name">
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message: "Enter your name.",
                                    whitespace: true,
                                },
                                {
                                    max: 60,
                                    message:
                                        "Name cannot be more than 60 characters.",
                                },
                            ],
                            initialValue: !!this.props.user.id
                                ? this.props.user.first_name +
                                  " " +
                                  this.props.user.last_name
                                : "",
                        })(
                            <Input
                                maxLength={60}
                                size="large"
                                onKeyDown={this.goNextField}
                                enterKeyHint="next"
                                placeholder="Enter your Name"
                                onKeyUp={this.validateForm}
                                style={{ marginTop: "5px" }}
                                disabled={this.state.saving}
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Your Email">
                        {getFieldDecorator("email", {
                            rules: [
                                {
                                    required: true,
                                    type: "email",
                                    message: "Enter a valid Email.",
                                    whitespace: true,
                                },
                                {
                                    max: 60,
                                    message:
                                        "Email cannot be more than 60 characters.",
                                },
                            ],
                            initialValue: !!this.props.user.id
                                ? this.props.user.email
                                : "",
                        })(
                            <Input
                                type="email"
                                maxLength={60}
                                size="large"
                                onKeyDown={this.goNextField}
                                enterKeyHint="next"
                                placeholder="Enter your Email"
                                onKeyUp={this.validateForm}
                                style={{ marginTop: "5px" }}
                                disabled={this.state.saving}
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Mobile Number">
                        {getFieldDecorator("mobile_phone", {
                            getValueFromEvent: (
                                e: React.FormEvent<HTMLInputElement>
                            ) => {
                                if (isNaN(Number(e.target.value))) {
                                    return this.props.form.getFieldValue(
                                        "mobile_phone"
                                    );
                                } else {
                                    return e.target.value;
                                }
                            },
                            rules: [
                                {
                                    required: true,
                                    message: "Enter your Mobile Number.",
                                    whitespace: true,
                                },
                                {
                                    min: 10,
                                    message:
                                        "Mobile Number must be 10 characters.",
                                },
                            ],
                            initialValue: !!this.props.user.id
                                ? this.props.user.mobile_phone
                                : "",
                        })(
                            <Input
                                size="large"
                                onKeyDown={this.goNextField}
                                enterKeyHint="next"
                                placeholder="Mobile Phone"
                                type="tel"
                                maxLength={10}
                                style={{ marginTop: "5px" }}
                                onKeyUp={this.validateForm}
                                disabled={this.state.saving}
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Subject">
                        {getFieldDecorator("subject", {
                            rules: [
                                {
                                    required:
                                        this.props.form.getFieldValue(
                                            "message"
                                        ) === "" ||
                                        typeof this.props.form.getFieldValue(
                                            "message"
                                        ) === "undefined",
                                    message: "Enter a subject.",
                                    whitespace: true,
                                },
                                {
                                    max: 100,
                                    message:
                                        "Subject cannot be more than 100 characters.",
                                },
                            ],
                        })(
                            <Input
                                maxLength={100}
                                size="large"
                                onKeyDown={this.goNextField}
                                enterKeyHint="next"
                                placeholder=""
                                onKeyUp={this.validateForm}
                                style={{ marginTop: "5px" }}
                                disabled={this.state.saving}
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Message">
                        {getFieldDecorator("message", {
                            rules: [
                                {
                                    required:
                                        this.props.form.getFieldValue(
                                            "subject"
                                        ) === "" ||
                                        typeof this.props.form.getFieldValue(
                                            "subject"
                                        ) === "undefined",
                                    message: "Enter a message.",
                                    whitespace: true,
                                },
                            ],
                        })(
                            <Input.TextArea
                                rows={4}
                                size="large"
                                enterKeyHint="next"
                                autoSize={false}
                                maxLength={1000}
                                onKeyUp={this.validateForm}
                                style={{
                                    marginTop: "5px",
                                    borderRadius: "4px",
                                    padding: "11px 6px",
                                    fontSize: "16px",
                                    fontWeight: 300,
                                    border: "1px solid #d9d9d9",
                                    color: "rgba(0,0,0,.65)",
                                }}
                                disabled={this.state.saving}
                            />
                        )}
                    </Form.Item>

                    <Form.Item>
                        <Button
                            style={{ width: "100%", marginTop: "1em" }}
                            type="primary"
                            htmlType="submit"
                            size="large"
                            onClick={() => this.submitForm()}
                            disabled={
                                this.state.submitButtonDisabled ||
                                this.state.saving
                            }
                            loading={this.state.saving}
                        >
                            Send
                        </Button>
                    </Form.Item>
                </Form>
            </>
        );

        if (this.props.embed) {
            return ReactDOM.createPortal(
                ContactFormToRender,
                document.getElementById("react-contact-form")
            );
        }

        return ContactFormToRender;
    }
}

const ContactForm = Form.create({ name: "contact_form" })(ContactFormClass);
export default withRouter(ContactForm);
