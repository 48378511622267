const {
    REACT_APP_ENVIRONMENT,
    REACT_APP_ENVIRONMENT_SHORT,
    REACT_APP_SHORT_NAME,
    REACT_APP_MEDIUM_NAME,
    REACT_APP_MEDIUM_NAME_WITH_PREFIX,
    REACT_APP_LONG_NAME,
    REACT_APP_LONG_NAME_WITH_PREFIX,
    REACT_APP_SITE_URL,
    REACT_APP_PUBLIC_SITE_URL,
    REACT_APP_DOMAIN,
    REACT_APP_API_URL,
    REACT_APP_GA_TRACKING_KEY,
    REACT_APP_FACEBOOK_APP_ID,
    REACT_APP_GOOGLE_CLIENT_ID,
    REACT_APP_IPSI_SERVER,
    REACT_APP_IPSI_CONFIG_ID,
    REACT_APP_IPSI_USERNAME,
    REACT_APP_IPSI_SECKEY,
} = process.env;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    showCompetition: false, //@todo: Set to true when Competition active.

    apiRoot: REACT_APP_API_URL,
    siteUrl: REACT_APP_SITE_URL,
    publicSiteUrl: REACT_APP_PUBLIC_SITE_URL,
    siteDomain: REACT_APP_DOMAIN,
    facebookAppId: REACT_APP_FACEBOOK_APP_ID,
    googleClientId: REACT_APP_GOOGLE_CLIENT_ID,
    googleAnalyticsTrackingKey: REACT_APP_GA_TRACKING_KEY,
    drivingSchoolShortName: REACT_APP_SHORT_NAME,
    drivingSchoolMediumName: REACT_APP_MEDIUM_NAME,
    drivingSchoolMediumNameWithPrefix: REACT_APP_MEDIUM_NAME_WITH_PREFIX,
    drivingSchoolLongName: REACT_APP_LONG_NAME,
    drivingSchoolLongNameWithPrefix: REACT_APP_LONG_NAME_WITH_PREFIX,
    environment: REACT_APP_ENVIRONMENT,
    environmentShort: REACT_APP_ENVIRONMENT_SHORT,
    maximumBookingsPerTransaction: 10,
    products: {
        singleLesson: 4,
        threeLesson: 22,
        fourLesson: 23,
        fiveLesson: 24,
        sixLesson: 8,
        eightLesson: 25,
        tenLesson: 9,
        twelveLesson: 26,
        twentyFiveLesson: 17,
        fortyLesson: 27,
        sdcModOne: 10,
        sdcModTwo: 11,
        agedAssessment: 18,
    },
    voucherNumberOfLessonsByProductID: {
        4: 1,
        8: 6,
        9: 10,
        17: 25,
        22: 3,
    },
    voucherLessonQuantityToProductID: {
        1: 4,
        2: 4,
        3: 4,
        4: 4,
        5: 4,
        6: 8,
        10: 9,
        25: 17,
    },
    bookingTypes: {
        lesson: 1,
        test: 2,
        aged: 3,
        k2d: 4,
        cert4: 5,
    },

    IPSI_server: REACT_APP_IPSI_SERVER,
    IPSI_configId: REACT_APP_IPSI_CONFIG_ID,
    IPSI_userName: REACT_APP_IPSI_USERNAME,
    IPSI_secKey: REACT_APP_IPSI_SECKEY,

    processingFees: {
        paypal: 2.6,
        afterpay: 6,
        zip: 5,
    },
};
